import { DateTime } from 'shared/luxon/luxon';
import { EnergyMeterDateInterval } from 'types/energyMeter/energyMeterDateInterval';
import { EnergyMeterMetricType } from 'types/energyMeter/energyMeterMetricType';
import {
    SET_ALL_FILTERS_RESET,
    SET_ENERGY_CONSUMPTION_AVAILABLE_DATES,
    SET_ENERGY_CONSUMPTION_FILTER,
    SET_ENERGY_POWER_AVAILABLE_DATES,
    SET_ENERGY_POWER_FILTER,
    SET_FOOD_SAFETY_REPORTING_DATE,
    SET_FOOD_SAFETY_STATISTICS_WEEK_AND_YEAR,
    SetAllFiltersResetActionTypes,
    SetEnergyConsumptionAvailableDatesActionTypes,
    SetEnergyConsumptionFilterActionTypes,
    SetEnergyPowerAvailableDatesActionTypes,
    SetEnergyPowerFilterActionTypes,
    SetFoodSafetyReportingDateActionTypes,
    SetFoodSafetyStatisticsWeekAndYearActionTypes,
    SetFoodSafetyStatisticDateFilterTypeActionTypes,
    SET_FOOD_SAFETY_STATISTIC_DATE_FILTER_TYPE,
    SetFoodSafetyStatisticsMonthAndYearActionTypes,
    SET_FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR,
    SetFoodSafetyStatisticsQSAndYearActionTypes,
    SET_FOOD_SAFETY_STATISTICS_QS_AND_YEAR,
    SetAlarmDurationActionTypes,
    SET_ALARM_DURATION,
    SetAlarmPriorityActionTypes,
    SET_ALARM_PRIORITY,
    SET_ALARM_DATE_FROM,
    SetAlarmDateFromActionTypes,
    SetAlarmDateToActionTypes,
    SET_ALARM_DATE_TO,
    SetAlarmCountActionTypes,
    SET_ALARM_COUNT,
    SET_ALARM_DATA_REFRESHED,
    SetAlarmDataRefreshed,
} from 'store/filters/types';
import { clearEnergyConsumptionFilter, setEnergyConsumptionFilter } from 'localStorage/energyConsumptionFilter';
import { clearEnergyPowerFilter, setEnergyPowerFilter } from 'localStorage/energyPowerFilter';
import { setFoodSafetyReportingFilter } from 'localStorage/foodSafetyReportingFilter';
import { setFoodSafetyStatisticsFilter } from 'localStorage/foodSafetyStatisticsFilter';
import { clearEnergyConsumptionAvailableDates, setEnergyConsumptionAvailableDates } from '../../localStorage/energyConsumptionAvailableDates';
import { clearEnergyPowerAvailableDates, setEnergyPowerAvailableDates } from '../../localStorage/energyPowerAvailableDates';
import { FoodSafetySegments } from 'types/foodSafety/foodSafetySegments';
import { setFoodSafetyStatisticsDateTypeFilter } from 'localStorage/foodSafetyStatisticDateTypeFilter';
import { setFoodSafetyStatisticsMonthAndYearFilter } from 'localStorage/foodSafetyStatisticsMonthAndYearFilter';
import { setFoodSafetyStatisticsQSAndYearFilter } from '../../localStorage/foodSafetyStatisticsQSAndYearFilter';

export const alarmReportDataRefreshed = (dataRefreshed: string): SetAlarmDataRefreshed => {
    return {
        type: SET_ALARM_DATA_REFRESHED,
        dataRefreshed,
    };
};

export const alarmReportCount = (alarmCount: number): SetAlarmCountActionTypes => {
    return {
        type: SET_ALARM_COUNT,
        alarmCount,
    };
};

export const alarmReportDateFrom = (dateFrom: DateTime): SetAlarmDateFromActionTypes => {
    return {
        type: SET_ALARM_DATE_FROM,
        dateFrom,
    };
};

export const alarmReportDateTo = (dateTo: DateTime): SetAlarmDateToActionTypes => {
    return {
        type: SET_ALARM_DATE_TO,
        dateTo,
    };
};

export const alarmReportPriority = (priority: number): SetAlarmPriorityActionTypes => {
    return {
        type: SET_ALARM_PRIORITY,
        priority,
    };
};

export const alarmReportDuration = (duration: number): SetAlarmDurationActionTypes => {
    return {
        type: SET_ALARM_DURATION,
        duration,
    };
};

export const foodSafetyReportingDate = (date: DateTime): SetFoodSafetyReportingDateActionTypes => {
    setFoodSafetyReportingFilter({
        date: date.toMillis(),
    });
    return {
        type: SET_FOOD_SAFETY_REPORTING_DATE,
        date,
    };
};

export const foodSafetyStatisticsWeekAndYear = (week: number, year: number): SetFoodSafetyStatisticsWeekAndYearActionTypes => {
    setFoodSafetyStatisticsFilter({
        week,
        year,
    });
    return {
        type: SET_FOOD_SAFETY_STATISTICS_WEEK_AND_YEAR,
        weekAndYear: {
            week,
            year,
        },
    };
};

export const foodSafetyStatisticsMonthAndYear = (month: number, year: number): SetFoodSafetyStatisticsMonthAndYearActionTypes => {
    setFoodSafetyStatisticsMonthAndYearFilter({
        month,
        year,
    });
    return {
        type: SET_FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR,
        monthAndYear: {
            month,
            year,
        },
    };
};

export const foodSafetyStatisticsQsAndYear = (quarter: number, year: number): SetFoodSafetyStatisticsQSAndYearActionTypes => {
    setFoodSafetyStatisticsQSAndYearFilter({
        quarter,
        year,
    });
    return {
        type: SET_FOOD_SAFETY_STATISTICS_QS_AND_YEAR,
        qsAndYear: {
            quarter,
            year,
        },
    };
};

export const foodSafetyStatisticsDateFilterType = (dateFilterType: keyof typeof FoodSafetySegments): SetFoodSafetyStatisticDateFilterTypeActionTypes => {
    setFoodSafetyStatisticsDateTypeFilter({
        dateFilterType,
    });
    return {
        type: SET_FOOD_SAFETY_STATISTIC_DATE_FILTER_TYPE,
        dateFilterType,
    };
};

export const energyConsumptionFilter = (
    dateFrom: DateTime,
    dateTo: DateTime,
    energyMetricType: EnergyMeterMetricType,
    dateIntervalType: EnergyMeterDateInterval,
): SetEnergyConsumptionFilterActionTypes => {
    setEnergyConsumptionFilter({
        dateFrom: dateFrom.toMillis(),
        dateTo: dateTo.toMillis(),
        energyMetricType,
        dateIntervalType,
    });
    return {
        type: SET_ENERGY_CONSUMPTION_FILTER,
        dateFrom,
        dateTo,
        energyMetricType,
        dateIntervalType,
    };
};

export const energyConsumptionAvailableDates = (availableDateFrom: DateTime, availableDateTo: DateTime): SetEnergyConsumptionAvailableDatesActionTypes => {
    setEnergyConsumptionAvailableDates({
        availableDateFrom: availableDateFrom.toMillis(),
        availableDateTo: availableDateTo.toMillis(),
    });
    return {
        type: SET_ENERGY_CONSUMPTION_AVAILABLE_DATES,
        availableDateFrom,
        availableDateTo,
    };
};

export const energyPowerFilter = (dateFrom: DateTime, dateTo: DateTime, energyMetricType: EnergyMeterMetricType): SetEnergyPowerFilterActionTypes => {
    setEnergyPowerFilter({
        dateFrom: dateFrom.toMillis(),
        dateTo: dateTo.toMillis(),
        energyMetricType,
    });
    return {
        type: SET_ENERGY_POWER_FILTER,
        dateFrom,
        dateTo,
        energyMetricType,
    };
};

export const energyPowerAvailableDates = (availableDateFrom: DateTime, availableDateTo: DateTime): SetEnergyPowerAvailableDatesActionTypes => {
    setEnergyPowerAvailableDates({
        availableDateFrom: availableDateFrom.toMillis(),
        availableDateTo: availableDateTo.toMillis(),
    });
    return {
        type: SET_ENERGY_POWER_AVAILABLE_DATES,
        availableDateFrom,
        availableDateTo,
    };
};

export const allFiltersReset = (): SetAllFiltersResetActionTypes => {
    clearEnergyConsumptionFilter();
    clearEnergyConsumptionAvailableDates();
    clearEnergyPowerFilter();
    clearEnergyPowerAvailableDates();
    return {
        type: SET_ALL_FILTERS_RESET,
    };
};
