import * as z from 'zod';

export const AlarmReportDataItemSchema = z.object({
    id: z.number(),
    alarm: z.any().optional(),
    assetId: z.number().nullable(),
    assetName: z.string().nullable(),
    can: z.number(),
    zone: z.string(),
    event: z.string(),
    priority: z.number(),
    position: z.string().nullable(),
    startOn: z.string(),
    endOn: z.string().optional().nullable(),
    comment: z.string().nullable(),
});

export const AlarmReportDataSchema = z.object({
    alarms: z.array(AlarmReportDataItemSchema),
    dataRefreshed: z.string().nullable(),
});

export const AlarmReportSchema = z.object({
    data: AlarmReportDataSchema,
});

export type AlarmReport = z.infer<typeof AlarmReportSchema>;
export type AlarmReportItem = z.infer<typeof AlarmReportDataItemSchema>;
