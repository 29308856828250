import { AppState } from 'store';
import { fetchMyProfile } from 'api/fetchers/myProfile';
import { isUserLoggedIn } from 'cookies/auth';
import { logout, myProfile } from 'store/auth/actions';
import { QueryKeys } from 'api/queryKeys';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdminLogsScreen from 'screens/adminLogsScreen';
import AdminUsersScreen from 'screens/adminUsersScreen';
import AssetScreen from 'screens/assetScreen';
import ChooseStoreScreen from 'screens/chooseStoreScreen';
import EnergyScreen from 'screens/energyScreen';
import FoodSafetyScreen from 'screens/foodSafetyScreen';
import ForgottenPasswordScreen from 'screens/forgottenPasswordScreen';
import LayoutScreen from 'screens/layoutScreen';
import MyProfileScreen from 'screens/myProfileScreen';
import React from 'react';
import ResetPasswordScreen from 'screens/resetPasswordScreen';
import SetupScreen from 'screens/setupScreen';
import SignInScreen from 'screens/signInScreen';
import useLanguages from 'shared/useLanguages/useLanguages';
import UserCreateScreen from 'screens/userCreateScreen';
import UserScreen from 'screens/userScreen';
import UsersScreen from 'screens/usersScreen';
import ReportsScreen from '../screens/reportsScreen';
import AlarmScreen from 'screens/alarmScreen';

const RoutesSwitcher: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { i18n } = useTranslation();
    const { authenticated } = useSelector((state: AppState) => state.auth);
    const { data, isSuccess } = useHandledQuery([QueryKeys.myProfile(), authenticated], fetchMyProfile, {
        enabled: authenticated,
    });
    const { changeLanguageByLanguageId, getLanguageCodeByLanguageId } = useLanguages();

    const { storeId } = useSelector((state: AppState) => state.store);
    React.useEffect(() => {
        if (!isUserLoggedIn()) {
            logout();
        }
    }, []);

    React.useEffect(() => {
        if (data && isSuccess) {
            dispatch(myProfile(data));
        }
    }, [dispatch, data, isSuccess]);

    React.useEffect(() => {
        if (!storeId && authenticated) {
            history.push(routes.chooseStore.path());
        }
    }, [storeId, history, authenticated]);

    React.useEffect(() => {
        if (data && isSuccess) {
            if (getLanguageCodeByLanguageId(data.languageId) !== i18n.language) {
                changeLanguageByLanguageId(data.languageId);
            }
        }
    }, [data, isSuccess, i18n, changeLanguageByLanguageId, getLanguageCodeByLanguageId]);

    return (
        <>
            {!authenticated ? (
                <Switch>
                    <Route component={ForgottenPasswordScreen} path={routes.forgottenPassword.pattern} />
                    <Route component={ResetPasswordScreen} path={routes.resetPassword.pattern} />
                    <Route component={SignInScreen} path="*" />
                </Switch>
            ) : (
                <Switch>
                    <Route component={ChooseStoreScreen} path={routes.chooseStore.pattern} />
                    <Route exact path="/">
                        <Redirect to={routes.foodSafetyReporting.path()} />
                    </Route>
                    <Route component={MyProfileScreen} path={routes.myProfile.pattern} />
                    <Route component={UserScreen} path={routes.user.pattern} />
                    <Route component={FoodSafetyScreen} path={routes.foodSafety.pattern} />
                    <Route component={EnergyScreen} path={routes.energy.pattern} />
                    <Route component={SetupScreen} path={routes.setup.pattern} />
                    <Route component={UsersScreen} path={routes.users.pattern} />
                    <Route component={ReportsScreen} path={routes.reports.pattern} />
                    <Route component={LayoutScreen} path={routes.layout.pattern} />
                    <Route component={AdminUsersScreen} path={routes.adminUsers.pattern} />
                    <Route component={UserCreateScreen} path={routes.userCreate.pattern} />
                    <Route component={AdminLogsScreen} path={routes.adminLogs.pattern} />
                    <Route component={AssetScreen} path={routes.asset.pattern} />
                    <Route component={AlarmScreen} path={routes.alarm.pattern} />
                    <Route component={() => <>404</>} exact path="*" />
                </Switch>
            )}
        </>
    );
};

export default RoutesSwitcher;
