import { Cell, Filters, SortingRule, TableOptions, useExpanded, useFilters, useSortBy, useTable } from 'react-table';
import { useStyles } from 'components/table/stylesTable';
import { useTheme, makeStyles } from '@material-ui/core';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import { InView } from 'react-intersection-observer';
import clsx from 'clsx';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    onSort: (rule: SortingRule<string> | undefined) => void;
    onFilter: (rule: Filters<{}> | undefined) => void;
}

const AlarmListViewTable = <T extends Record<string, unknown>>({ columns, data, onSort, isFetching, onFilter }: React.PropsWithChildren<TableProperties<T>>) => {
    const [tableRecords, setTableRecords] = React.useState<T[]>([]);
    const getRowId = React.useCallback((row) => {
        return row.id;
    }, []);
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy, filters },
        prepareRow,
    } = useTable<T>(
        {
            columns,
            data: tableRecords,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            manualSortBy: true,
            manualFilters: true,
            getRowId,
        },
        useFilters,
        useSortBy,
        useExpanded,
    );

    React.useEffect(() => {
        if (data) {
            setTableRecords(data);
        }
    }, [data]);

    React.useEffect(() => {
        onFilter(filters);
    }, [filters, onFilter]);

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    const classes = {
        ...useStyles(),
        ...makeStyles(() => ({
            tableRoot: {
                overflow: 'hidden',
            },
            tableRowDefault: {
                '& td:first-child': {
                    color: '#1891F6',
                    fontWeight: 500,
                },
            },
            tableRowWithAlarm: {
                '&:hover': {
                    background: 'linear-gradient(0deg, rgba(233, 0, 56, 0.16), rgba(233, 0, 56, 0.16)), linear-gradient(0deg, rgba(233, 0, 56, 0.16), rgba(233, 0, 56, 0.16))',
                    '& td:first-child': {
                        color: 'red',
                    },
                },
            },
            tableRowWithoutAlarm: {
                background: 'rgba(0, 0, 0, 0.04)',
                '& td': { opacity: '40%' },
                '& td:first-child': { color: 'inherit' },
            },
        }))(),
    };

    const getAlarmPriority = (alarm: any): number | undefined => {
        for (const key in alarm) {
            if (Object.prototype.hasOwnProperty.call(alarm, key)) {
                const priorityValue = alarm[key].priority;
                if (priorityValue === 1 || priorityValue === 2) {
                    return priorityValue;
                }
            }
        }
        return undefined;
    };
    const getTableRowClass = (alarmPriority: number | undefined) => {
        if (alarmPriority === 1) {
            return classes.tableRowWithAlarm;
        } else if (alarmPriority === 2) {
            return undefined;
        } else {
            return classes.tableRowWithoutAlarm;
        }
    };
    return (
        <table {...getTableProps()} className={clsx(classes.root, classes.tableRoot)}>
            <TableHead headerGroups={headerGroups} />
            <tbody {...getTableBodyProps()}>
                {rows.length === 0 && !isFetching && (
                    <tr>
                        <td colSpan={headerGroups[0].headers.length + 24}>
                            <NoResults />
                        </td>
                    </tr>
                )}
                {rows.map((row) => {
                    const alarmPriority = getAlarmPriority(row.original.alarm);
                    const tableRowClass = getTableRowClass(alarmPriority);

                    prepareRow(row);
                    return (
                        <InView key={`${row.id}-${row.index}`} rootMargin="0px 4000px 0px 0px" triggerOnce={false}>
                            {({ ref, inView }) => (
                                <>
                                    <div ref={ref} style={{ width: '100%', position: 'absolute' }} />
                                    {inView ? (
                                        <>
                                            <tr className={clsx(classes.tableRow, classes.tableRowDefault, tableRowClass)}>
                                                {row.cells.map((cell: Cell<T>) => {
                                                    return (
                                                        <React.Fragment key={`${cell.column.id}-${cell.row.id}`}>
                                                            <td {...cell.getCellProps()} className={`${classes.tableCell} ${cell.column.id}`} style={cell.column.customStyles}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tr>
                                        </>
                                    ) : (
                                        <div style={{ height: isLgUp ? 47 : 131 }} />
                                    )}
                                </>
                            )}
                        </InView>
                    );
                })}
            </tbody>
        </table>
    );
};

export default AlarmListViewTable;
