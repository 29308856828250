import { Cell, SortingRule, TableOptions, useExpanded, useFilters, useSortBy, useTable } from 'react-table';
import { useStyles } from 'components/table/stylesTable';
import { useTheme, makeStyles } from '@material-ui/core';
import DefaultColumnFilter from 'components/table/components/defaultColumnFilter/defaultColumnFilter';
import NoResults from 'components/noResults/noResults';
import React from 'react';
import TableHead from 'components/table/components/tableHead/tableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ValueDisplay from 'components/valueDisplay/valueDisplay';
import { InView } from 'react-intersection-observer';
import clsx from 'clsx';

export interface TableProperties<T extends Record<string, unknown>> extends TableOptions<T> {
    isFetching: boolean;
    onSort: (rule: SortingRule<string> | undefined) => void;
    refetch: () => void;
}

const AlarmTableViewTable = <T extends Record<string, unknown>>({ columns, data, onSort, isFetching, refetch }: React.PropsWithChildren<TableProperties<T>>) => {
    const [tableRecords, setTableRecords] = React.useState<T[]>([]);
    const getRowId = React.useCallback((row) => {
        return row.id;
    }, []);
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { sortBy },
        prepareRow,
    } = useTable<T>(
        {
            columns,
            data: tableRecords,
            defaultColumn: {
                Filter: DefaultColumnFilter,
                disableFilters: true,
                disableSortBy: true,
                Cell: ValueDisplay,
            },
            manualSortBy: true,
            manualFilters: true,
            getRowId,
        },
        useFilters,
        useSortBy,
        useExpanded,
    );

    React.useEffect(() => {
        if (data) {
            setTableRecords(data);
        }
    }, [data]);

    React.useEffect(() => {
        onSort(sortBy[0]);
    }, [sortBy, onSort]);

    const classes = {
        ...useStyles(),
        ...makeStyles(() => ({
            tableRowDefault: {
                '& td:first-child': {
                    color: '#1891F6',
                    fontWeight: 500,
                },
            },
        }))(),
    };

    return (
        <table {...getTableProps()} className={classes.root}>
            <TableHead headerGroups={headerGroups} />
            <tbody {...getTableBodyProps()}>
                {rows.length === 0 && !isFetching && (
                    <tr>
                        <td colSpan={headerGroups[0].headers.length + (isLgUp ? 24 : 0)}>
                            <NoResults />
                        </td>
                    </tr>
                )}
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <InView key={`${row.id}-${row.index}`} triggerOnce={false}>
                            {({ ref, inView }) => (
                                <>
                                    <div ref={ref} style={{ width: '100%', position: 'absolute' }} />
                                    {inView ? (
                                        <>
                                            <tr className={clsx(classes.tableRow, classes.tableRowDefault)}>
                                                {row.cells.map((cell: Cell<T>) => {
                                                    return (
                                                        <React.Fragment key={`${cell.column.id}-${cell.row.id}`}>
                                                            <td {...cell.getCellProps()} className={`${classes.tableCell} ${cell.column.id}`} style={cell.column.customStyles}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tr>
                                        </>
                                    ) : (
                                        <div style={{ height: isLgUp ? 47 : 131 }} />
                                    )}
                                </>
                            )}
                        </InView>
                    );
                })}
            </tbody>
        </table>
    );
};

export default AlarmTableViewTable;
