import { BadRequestErrorWithPaginationSortingFilteringErrorCodes } from 'api/errorCodes/badRequestErrorWithPaginationSortingFilteringErrorCodes';
import { Column, Filters, SortingRule } from 'react-table';
import { DateTime } from 'shared/luxon/luxon';
import { getLogsReporting } from 'api/fetchers/logsReporting';
import { LogsReportingItem } from 'model/logsReporting/schema';
import { LogsReportingTableAccessors } from 'types/logsReporting/logsReportingTableAccessors';
import { QueryKeys } from 'api/queryKeys';
import { ReportingEventType } from 'shared/enums/reportingEventType';
import { ReportType } from 'shared/enums/reportType';
import { transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useTranslation } from 'react-i18next';
import Content from 'components/content/content';
import DatepickerColumnFilter from 'components/table/components/datepickerColumnFilter/datepickerColumnFilter';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import LoadingBar from 'components/loadingBar/loadingBar';
import LogsReportingTable from 'components/table/instances/logsReportingTable/logsReportingTable';
import React from 'react';
import SelectColumnFilter from 'components/table/components/selectColumnFilter/selectColumnFilter';
import settings from 'settings';

const EmailReport: React.FC = () => {
    const { t } = useTranslation();
    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>(undefined);
    const [filters, setFilters] = React.useState<Filters<{}> | undefined>(undefined);
    const [pageIndex, setPageIndex] = React.useState<number>(0);
    const translateReportType = React.useCallback(
        (input: string) => {
            if (input === ReportType.energyPower) {
                return t('energy.power.title');
            } else if (input === ReportType.energyConsumption) {
                return t('energy.consumption.title');
            } else if (input === ReportType.foodSafetyStatistics) {
                return t('foodSafety.statistics.title');
            } else if (input === ReportType.foodSafetyReport) {
                return t('foodSafety.reporting.title');
            } else {
                return input;
            }
        },
        [t],
    );
    const translateReportingEventType = React.useCallback(
        (input: string) => {
            if (input === ReportingEventType.deliveryFailed) {
                return t('reportingEventType.deliveryFailed');
            } else if (input === ReportingEventType.deliveryDone) {
                return t('reportingEventType.deliveryDone');
            } else {
                return input;
            }
        },
        [t],
    );

    const reportTypeOptions = React.useMemo(
        () => [
            {
                value: ReportType.energyPower,
                label: translateReportType(ReportType.energyPower),
            },
            {
                value: ReportType.energyConsumption,
                label: translateReportType(ReportType.energyConsumption),
            },
            {
                value: ReportType.foodSafetyStatistics,
                label: translateReportType(ReportType.foodSafetyStatistics),
            },
            {
                value: ReportType.foodSafetyReport,
                label: translateReportType(ReportType.foodSafetyReport),
            },
        ],
        [translateReportType],
    );

    const reportingEventTypeOptions = React.useMemo(
        () => [
            {
                value: ReportingEventType.deliveryFailed,
                label: translateReportingEventType(ReportingEventType.deliveryFailed),
            },
            {
                value: ReportingEventType.deliveryDone,
                label: translateReportingEventType(ReportingEventType.deliveryDone),
            },
        ],
        [translateReportingEventType],
    );

    const columns = React.useMemo<Column<LogsReportingItem>[]>(() => {
        return [
            {
                Header: t('adminLogs.reporting.table.heading.date').toString(),
                accessor: LogsReportingTableAccessors.occurredOn,
                Cell: ({ value }) => DateTime.fromISO(value, { zone: 'UTC+1' }).toFormat(DateTimeFormat.FULL_24_WITHOUT_SECONDS()),
                Filter: DatepickerColumnFilter,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '10%',
                },
            },
            {
                Header: t('adminLogs.reporting.table.heading.reportType').toString(),
                accessor: LogsReportingTableAccessors.reportType,
                disableFilters: false,
                disableSortBy: false,
                Filter: ({ column }) => <SelectColumnFilter column={column} options={reportTypeOptions} />,
                Cell: ({ value }) => translateReportType(value),
                customStyles: {
                    width: '15%',
                },
            },
            {
                Header: t('adminLogs.reporting.table.heading.eventType').toString(),
                accessor: LogsReportingTableAccessors.eventType,
                disableFilters: false,
                disableSortBy: false,
                Filter: ({ column }) => <SelectColumnFilter column={column} options={reportingEventTypeOptions} />,
                Cell: ({ value }) => translateReportingEventType(value),
                customStyles: {
                    width: '15%',
                },
            },
            {
                Header: t('adminLogs.reporting.table.heading.reportingPeriod').toString(),
                accessor: LogsReportingTableAccessors.lastReportedDay,
                Cell: ({ value }) => DateTime.fromJSDate(new Date(value)).toFormat(DateTimeFormat.DATE_FORMAT()),
                Filter: DatepickerColumnFilter,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '10%',
                },
            },
            {
                Header: t('adminLogs.reporting.table.heading.contact').toString(),
                accessor: LogsReportingTableAccessors.contact,
                disableFilters: false,
                disableSortBy: false,
            },
            {
                Header: t('adminLogs.reporting.table.heading.storeName').toString(),
                accessor: LogsReportingTableAccessors.storeName,
                disableFilters: false,
                disableSortBy: false,
            },
        ];
    }, [t, reportTypeOptions, reportingEventTypeOptions, translateReportType, translateReportingEventType]);

    const transformDateRangeFilter = (filters: Filters<{}>, columns: string[]) => {
        const modifiedFilters = filters.filter(({ id }) => !columns.includes(id));
        columns.forEach((column: string) => {
            const dateRangeFilter = filters.find(({ id }) => id === column);
            if (dateRangeFilter && dateRangeFilter.value.isValid) {
                modifiedFilters.push({ id: column + 'Start', value: dateRangeFilter.value.startOf('day').toISO() }, { id: column + 'End', value: dateRangeFilter.value.endOf('day').toISO() });
            }
        });

        return modifiedFilters;
    };

    const queryParams = {
        sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
        filter: filters ? transformReactTableFiltersForAPI(transformDateRangeFilter(filters, ['occurredOn', 'lastReportedDay'])) : [],
        dataRange: {
            from: pageIndex * settings.pageSize,
            to: pageIndex * settings.pageSize + (settings.pageSize - 1),
        },
    };
    const { data, isFetching } = useHandledQuery(
        [QueryKeys.logsReporting(), queryParams],
        () => getLogsReporting(queryParams.dataRange, queryParams.sort, queryParams.filter),
        {
            keepPreviousData: true,
        },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    const handleFilters = (rule: Filters<{}> | undefined) => {
        setFilters(rule);
    };

    const handlePageIndexChange = (pageIndex: number) => {
        setPageIndex(pageIndex);
    };

    return (
        <Content>
            <LoadingBar isFetching={isFetching} />
            <LogsReportingTable
                columns={columns}
                data={data ? data.data : []}
                isFetching={isFetching}
                pageCount={data ? Math.ceil(data.metadata.count / settings.pageSize) : 0}
                onFilter={handleFilters}
                onPageIndexChange={handlePageIndexChange}
                onSort={handleSort}
            />
        </Content>
    );
};

export default EmailReport;
