import { makeQuery } from 'api/api';
import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, QueryParameter, SortParameter } from 'api/types';
import { generateTimeInterval } from 'components/table/instances/alarmListViewTable/utils';
import { DateTime, Interval } from 'luxon';
import { AlarmReport, AlarmReportItem, AlarmReportSchema } from 'model/alarmReport/schema';
import { getDaysOfWeek } from 'shared/utils/getDaysOfWeek';

interface Props {
    storeId: number;
    dateFrom: string;
    dateTo: string;
    duration: number;
    sort?: SortParameter;
    filter?: FilterParameter;
    query?: QueryParameter;
}

/**
 * append data to alarm if date startOn and/or end_on inside the given interval
 *
 * @returns
 */
export const getAlarmReportListView = async ({ storeId, dateFrom, dateTo, duration, sort, filter }: Props): Promise<AlarmReport> => {
    const response = await makeQuery(ApiEndpoints.getAlarmReport(storeId, dateFrom, dateTo), true, sort, filter);
    const parsedResponse = AlarmReportSchema.parse(response);
    const currentDateTime = parsedResponse.data.dataRefreshed ? DateTime.fromISO(parsedResponse.data.dataRefreshed) : DateTime.now();
    const getAlarmTime = (item: AlarmReportItem) => {
        const intervals = generateTimeInterval(currentDateTime, duration);
        const dateTimeIntervals = intervals.map(({ dateTime }) => {
            return dateTime;
        });
        const closestDate = findClosestDate(dateTimeIntervals, item.startOn, item.endOn);
        const [start] = dateTimeIntervals;
        const targetDateTime = DateTime.fromISO(item.startOn);

        const daysOfWeek = getDaysOfWeek();
        if (closestDate) {
            const alarm: Record<string, Object | undefined> = {};
            const hours = closestDate.hour;
            const minutes = closestDate.minute;

            let dayOfWeek = closestDate.weekday;
            dayOfWeek = (dayOfWeek + 6) % 7;
            const dayAcronym = daysOfWeek[dayOfWeek];

            alarm[`${dayAcronym}_${hours}_${minutes}`] = {
                priority: item.priority,
                closestDate,
                startOn: targetDateTime <= start && !item.endOn ? closestDate : item.startOn,
                endOn: item.endOn,
                comment: item.comment,
                event: item.event,
            };

            return alarm;
        }
    };
    const alarms = parsedResponse.data.alarms.map((item) => {
        return {
            ...item,
            alarm: getAlarmTime(item),
        };
    });

    return {
        data: {
            alarms,
            dataRefreshed: parsedResponse.data.dataRefreshed,
        },
    };
};

/**
 * Finds and returns the closest date from a given array of dates to a target date.
 *
 * @returns The closest date to the target date within the provided array, or null if the array is empty or the target date is outside the range of the input dates.
 */
const findClosestDate = (dates: DateTime[], targetDate: string, endOn?: string | null): DateTime | null => {
    if (!dates || dates.length === 0) {
        return null;
    }

    const targetDateTime = DateTime.fromISO(targetDate);
    const timeDifferences = dates.map((date) => Math.abs(targetDateTime.diff(date).as('milliseconds')));
    const minIndex = timeDifferences.indexOf(Math.min(...timeDifferences));

    const [start] = dates;
    const end = dates[dates.length - 1];

    const interval = Interval.fromDateTimes(start, end);
    const isWithinRange = interval.contains(targetDateTime);

    if (targetDateTime <= start && !endOn) {
        return dates[0];
    }

    if (!isWithinRange) {
        return null;
    }

    return dates[minIndex];
};
