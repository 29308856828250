import { ReportType } from 'shared/enums/reportType';

export const ApiEndpoints = {
    contactUs: () => 'reporting/contact-us',
    signIn: () => 'user/sign-in',
    signOut: () => 'user/sign-out',
    myProfile: () => 'user/my-profile',
    requestPasswordReset: () => 'user/request-password-reset',
    resetPassword: () => 'user/reset-password',
    getUser: (id: number) => `user/${id}`,
    getUsers: () => `user`,
    getStoreReportFiles: (storeId: number) => `reporting/store/${storeId}/report-files`,
    getStoreReportFileDownload: (storeId: number, hash: string) => `reporting/store/${storeId}/report-files/${hash}/download`,
    getStoreUsers: (storeId: number, includeAdmin: boolean) => `user/store/${storeId}/${+includeAdmin}`,
    putUser: (userId: number) => `user/${userId}`,
    postUser: () => 'user',
    postEnableUser: (userId: number) => `user/${userId}/enable`,
    postDisableUser: (userId: number) => `user/${userId}/disable`,
    putAccessRights: (userId: number) => `users/${userId}/access-rights`,
    putMyProfile: () => 'user/my-profile',
    stores: () => 'reporting/stores',
    getFoodSafetyReport: (storeId: number, date: string) => `reporting/store/${storeId}/food-safety-report/${date}`,
    getFoodSafetyReportXls: (storeId: number, date: string) => `reporting/store/${storeId}/food-safety-report/${date}/xls`,
    getFoodSafetyReportPdf: (storeId: number, date: string) => `reporting/store/${storeId}/food-safety-report/${date}/pdf`,
    getFoodSafetyReportMultiplePdfsData: (storeId: number, dateFrom: string, dateTo: string) => `reporting/store/${storeId}/food-safety-report/${dateFrom}/${dateTo}/pdf`,
    getFoodSafetyReportMultipleCsvData: (storeId: number, dateFrom: string, dateTo: string) => `reporting/store/${storeId}/food-safety-report/${dateFrom}/${dateTo}/csv`,
    getFoodSafetyStatistics: (storeId: number, year: number, week: number) => `reporting/store/${storeId}/food-safety-statistics/${year}/${week}`,
    getFoodSafetyStatisticsMonthly: (storeId: number, year: number, monthStart: number, monthEnd: number) =>
        `reporting/store/${storeId}/food-safety-statistics/monthly/${year}/${monthStart}/${monthEnd}`,
    getFoodSafetyStatisticsMultipleCsvData: (storeId: number, dateFrom: string, dateTo: string) => `reporting/store/${storeId}/food-safety-statistics/${dateFrom}/${dateTo}/csv`,
    getFoodSafetyStatisticsXls: (storeId: number, year: number, week: number) => `reporting/store/${storeId}/food-safety-statistics/${year}/${week}/xls`,
    getFoodSafetyStatisticsPdf: (storeId: number, year: number, week: number) => `reporting/store/${storeId}/food-safety-statistics/${year}/${week}/pdf`,
    getFoodSafetyStatisticsMonthlyPdf: (storeId: number, year: number, monthStart: number, monthEnd: number) => `reporting/store/${storeId}/food-safety-statistics/monthly/${year}/${monthStart}/${monthEnd}/pdf`,
    getOrganizationStructure: (userId: number, onlyUsers: boolean) => `reporting/organization-structure/${userId}/${+onlyUsers}`,
    getEnergyMetrics: (storeId: number, energyMetricType: string, dateFrom: string, dateTo: string, dateInterval: string) =>
        `reporting/store/${storeId}/energy/${energyMetricType}/${dateFrom}/${dateTo}/${dateInterval}`,
    getEnergyMetricsXls: (storeId: number, energyMetricType: string, dateFrom: string, dateTo: string, dateInterval: string) =>
        `reporting/store/${storeId}/energy/${energyMetricType}/${dateFrom}/${dateTo}/${dateInterval}/xls`,
    getEnergyMetricsCsv: (storeId: number, energyMetricType: string, dateFrom: string, dateTo: string) => `reporting/store/${storeId}/energy/${energyMetricType}/${dateFrom}/${dateTo}/default/csv`,
    getNotifications: (storeId: number) => `reporting/notifications/store/${storeId}`,
    getAlarmReport: (storeId: number, dateFrom: string, dateTo: string | null) => `reporting/alarm/${storeId}/${dateFrom}/${dateTo}`,
    getAlarmReportCsv: (storeId: number, dateFrom: string, dateTo: string) => `reporting/alarm/${storeId}/${dateFrom}/${dateTo}/csv`,
    postNotification: (storeId: number, userId: number) => `reporting/notifications/store/${storeId}/user/${userId}`,
    deleteNotification: (contactId: number) => `reporting/notifications/${contactId}`,
    patchNotificationEnable: (contactId: number) => `reporting/notifications/${contactId}/enable`,
    patchNotificationDisable: (contactId: number) => `reporting/notifications/${contactId}/disable`,
    patchNotificationReportTypeEnable: (contactId: number, reportType: ReportType) => `reporting/notifications/${contactId}/report/${reportType}/enable`,
    patchNotificationReportTypeDisable: (contactId: number, reportType: ReportType) => `reporting/notifications/${contactId}/report/${reportType}/disable`,
    logsUser: () => 'user/logs',
    logsReporting: () => 'reporting/logs',
    asset: (assetId: number, dateFrom: string, dateTo: string) => `reporting/asset/${assetId}/${dateFrom}/${dateTo}`,
    assetPdf: (assetId: number, dateFrom: string, dateTo: string) => `reporting/asset/${assetId}/${dateFrom}/${dateTo}/pdf`,
    assetCsv: (assetId: number, dateFrom: string, dateTo: string) => `reporting/asset/${assetId}/${dateFrom}/${dateTo}/csv`,
    assetPosition: () => 'reporting/asset/position',
    assetsVisibility: () => 'reporting/assets/visibility',
    reportingStoreLive: (storeId: number) => `reporting/store/${storeId}/live`,
};
