import { ButtonProps } from './buttonProps';
import { ReactComponent as LoaderIcon } from 'assets/icons/loader.svg';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MuiButton from '@material-ui/core/Button';
import React from 'react';

interface StylesProps extends Pick<ButtonProps, 'isPrimary' | 'size'> {}

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: ({ size }: StylesProps) => (size === 'large' ? theme.typography.pxToRem(16) : theme.typography.pxToRem(12)),
        borderRadius: theme.spacing(0.5),
        fontWeight: ({ size }: StylesProps) => (size === 'large' ? 600 : 400),
        padding: ({ isPrimary, size }: StylesProps) => {
            if (size === 'large') {
                if (isPrimary) {
                    return `0 ${theme.spacing(3)}`;
                } else {
                    return `0 ${theme.spacing(2.9375)}`;
                }
            } else if (size === 'medium') {
                if (isPrimary) {
                    return `0 ${theme.spacing(2)}`;
                } else {
                    return `0 ${theme.spacing(1.9375)}`;
                }
            } else {
                if (isPrimary) {
                    return `0 ${theme.spacing(1)}`;
                } else {
                    return `0 ${theme.spacing(0.9375)}`;
                }
            }
        },
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: 'auto',
        height: ({ size }: StylesProps) => {
            if (size === 'large') {
                return theme.spacing(5);
            } else if (size === 'medium') {
                return theme.spacing(4);
            } else {
                return theme.spacing(3);
            }
        },
        whiteSpace: 'nowrap',
        borderColor: ({ isPrimary }: StylesProps) => (isPrimary ? 'none' : theme.customPalette.border),
        color: ({ isPrimary }: StylesProps) => (isPrimary ? theme.customPalette.background.white : theme.customPalette.text.primary),
        background: ({ isPrimary }: StylesProps) => (isPrimary ? 'linear-gradient(225deg, #2699FF 0%, #2674FF 100%)' : theme.customPalette.background.default),
    },
    startIcon: {
        marginLeft: theme.typography.pxToRem(-2),
        marginRight: theme.typography.pxToRem(6),
        fill: ({ isPrimary }: StylesProps) => (isPrimary ? theme.customPalette.background.white : theme.customPalette.text.primary),
        '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
    },
    hiddenTextInnerWhenLoading: {
        opacity: 0,
    },
    loaderIconOuterCentered: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: ({ isPrimary }: StylesProps) => (!isPrimary ? theme.customPalette.colors.brand.light : 'unset'),
        '& svg': {
            height: ({ size }: StylesProps) => (size === 'large' ? theme.spacing(3) : theme.spacing(2)),
            width: ({ size }: StylesProps) => (size === 'large' ? theme.spacing(3) : theme.spacing(2)),
        },
    },
    loaderIcon: {
        color: ({ isPrimary }: StylesProps) => (!isPrimary ? theme.customPalette.colors.brand.light : 'unset'),
    },
}));

const Button: React.FC<ButtonProps> = ({ isLoading, className, children, disabled, icon, isPrimary, size, onClick, type, href, target, id }) => {
    const classes = useStyles({ isPrimary, size });
    return (
        <MuiButton
            classes={{
                root: classes.root,
                startIcon: classes.startIcon,
            }}
            className={clsx(className || false)}
            disabled={disabled}
            id={id}
            startIcon={icon && isLoading ? <LoaderIcon className={classes.loaderIcon} /> : icon}
            type={type}
            variant={isPrimary ? 'contained' : 'outlined'}
            onClick={onClick}
            {...(href ? { href } : {})}
            {...(target ? { target } : {})}
        >
            <span className={clsx(!icon && isLoading && classes.hiddenTextInnerWhenLoading)}>{children}</span>
            {!icon && isLoading && (
                <span className={classes.loaderIconOuterCentered}>
                    <LoaderIcon className={classes.loaderIcon} />
                </span>
            )}
        </MuiButton>
    );
};

export default Button;
