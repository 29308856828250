export const routes = {
    default: {
        path: () => '/',
        pattern: '/',
    },
    chooseStore: {
        path: () => '/choose-store',
        pattern: '/choose-store',
    },
    foodSafety: {
        path: () => '/food-safety',
        pattern: '/food-safety',
    },
    foodSafetyReporting: {
        path: () => '/food-safety/reporting',
        pattern: '/food-safety/reporting',
    },
    foodSafetyStatistics: {
        path: () => '/food-safety/statistics',
        pattern: '/food-safety/statistics',
    },
    energy: {
        path: () => '/energy',
        pattern: '/energy',
    },
    users: {
        path: () => '/users',
        pattern: '/users',
    },
    layout: {
        path: () => '/layout',
        pattern: '/layout',
    },
    reports: {
        path: () => '/reports',
        pattern: '/reports',
    },
    adminUsers: {
        path: () => '/admin/users',
        pattern: '/admin/users',
    },
    energyConsumption: {
        path: () => '/energy/consumption',
        pattern: '/energy/consumption',
    },
    energyPower: {
        path: () => '/energy/power',
        pattern: '/energy/power',
    },
    setup: {
        path: () => '/setup',
        pattern: '/setup',
    },
    setupEmailReportConfig: {
        path: () => '/setup/email-report-config',
        pattern: '/setup/email-report-config',
    },
    forgottenPassword: {
        path: () => '/forgotten-password',
        pattern: '/forgotten-password',
    },
    resetPassword: {
        path: () => '/reset-password',
        pattern: '/reset-password',
    },
    user: {
        path: (userId: number) => `/user/${userId}`,
        pattern: '/user/:id',
    },
    userDetail: {
        path: (userId: number) => `/user/${userId}/detail`,
        pattern: '/user/:id/detail',
    },
    userAccesses: {
        path: (userId: number) => `/user/${userId}/accesses`,
        pattern: '/user/:id/accesses',
    },
    userAccessesDetail: {
        path: (userId: number) => `/user/${userId}/accesses/detail`,
        pattern: '/user/:id/accesses/detail',
    },
    userAccessesEdit: {
        path: (userId: number) => `/user/${userId}/accesses/edit`,
        pattern: '/user/:id/accesses/edit',
    },
    myProfile: {
        path: () => '/my-profile',
        pattern: '/my-profile',
    },
    myProfileUserSettings: {
        path: () => '/my-profile/user-settings',
        pattern: '/my-profile/user-settings',
    },
    myProfileHelp: {
        path: () => '/my-profile/help',
        pattern: '/my-profile/help',
    },
    userCreate: {
        path: () => '/create-user',
        pattern: '/create-user',
    },
    adminLogs: {
        path: () => '/admin/logs',
        pattern: '/admin/logs',
    },
    adminLogsUser: {
        path: () => '/admin/logs/user',
        pattern: '/admin/logs/user',
    },
    adminEmailReport: {
        path: () => '/admin/logs/email-report',
        pattern: '/admin/logs/email-report',
    },
    asset: {
        path: (assetId: number) => `/asset/${assetId}`,
        pattern: '/asset/:id',
    },
    alarm: {
        path: () => '/alarm',
        pattern: '/alarm',
    },
    alarmListView: {
        path: () => '/alarm/list-view',
        pattern: '/alarm/list-view',
    },
    alarmTableView: {
        path: () => '/alarm/table-view',
        pattern: '/alarm/table-view',
    },
};
