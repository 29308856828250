import { makeQuery } from 'api/api';
import { ApiEndpoints } from 'api/endpoints';
import { FilterParameter, SortParameter } from 'api/types';
import { AlarmReportSchema } from 'model/alarmReport/schema';

interface Props {
    storeId: number;
    dateFrom: string;
    dateTo: string;
    sort?: SortParameter;
    filter?: FilterParameter;
}

export const getAlarmReportTableView = async ({ storeId, dateFrom, dateTo, sort, filter }: Props) => {
    const response = await makeQuery(ApiEndpoints.getAlarmReport(storeId, dateFrom, dateTo), true, sort, filter);
    return AlarmReportSchema.parse(response);
};
