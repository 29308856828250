import { AlarmCellProps } from 'components/table/components/alarmCell/types';
import Tooltip from '@material-ui/core/Tooltip';
import { getDurationToMinutes } from 'components/table/instances/alarmListViewTable/utils';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import theme from 'theme/theme';

const AlarmCell: React.FC<AlarmCellProps> = ({ value }) => {
    const { duration, dataRefreshed } = useSelector((state: AppState) => state.filters.alarmReport);

    const getPriorityColor = (priority: number): string => {
        switch (priority) {
            case 1:
                return theme.customPalette.colors.error;
            case 2:
                return theme.customPalette.colors.warning;
            default:
                return '';
        }
    };

    const getPriorityColorLinear = (priority: number): string => {
        switch (priority) {
            case 1:
                return `linear-gradient(90deg, #E90038 0%, #E90038 50%, rgba(233, 0, 56, 0) 100%), linear-gradient(0deg, transparent, transparent)`;
            case 2:
                return `linear-gradient(90deg, rgba(241, 140, 30, 1) 0%,rgba(241, 140, 30, 1) 50%, rgba(255, 92, 0, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)`;
            default:
                return '';
        }
    };

    /**
     * Renders an alarm bar component to visually represent an alarm's duration and position within a timeline.
     *
     * @param {object} value - An object containing alarm information:
     *   - startOn: {Date} The start time of the alarm.
     *   - endOn: {Date} (optional) The end time of the alarm.
     *   - comment: {string} A comment associated with the alarm.
     *   - priority: {string} The priority level of the alarm.
     *   - closestDate: {Date} The closest date to the current view within the timeline.
     * @param {string} duration - A string representing the duration of the timeline view (e.g., "4 hours").
     * @returns {JSX.Element} A React element representing the alarm bar.
     */
    const alarmBar = () => {
        if (!value) {
            return null;
        }

        const start = DateTime.fromJSDate(new Date(value.startOn));
        const startTimeFormat = start.toFormat('HH:mm');

        const end = DateTime.fromJSDate(new Date(value.endOn));
        const endTimeFormat = end.toFormat('HH:mm');

        const title = `${value.event || ''} ${startTimeFormat} - ${value.endOn ? endTimeFormat : ''}`;
        const priorityColor = getPriorityColor(value.priority);

        const durationToMinutes = getDurationToMinutes(duration);

        // Bar position
        const closestDate = DateTime.fromJSDate(new Date(value.closestDate));
        const previousDate = closestDate.minus({ minutes: durationToMinutes });
        const nextDate = closestDate.plus({ minutes: durationToMinutes });
        const totalTimeRange = nextDate.diff(previousDate, 'minutes').toObject().minutes || 0;
        const difference = start.diff(previousDate, 'minutes').toObject().minutes || 0;
        /**
         * The 200% means 2 columns, from previous date and next date
         * The default position of the bar is to the center of the closest date, aligned with vertical lines
         * The -50% is to set the bar position to the previousDate as the starting position
         * since it's in the center and it has a free space of 50% both sides
         */
        const ratio = (difference / totalTimeRange) * 200;
        const barPosition = ratio - 50;

        // Total width
        const totalMinutes = Math.abs(Math.floor(end.diff(start).as('minutes')));
        const barWidth = Math.abs((totalMinutes / durationToMinutes) * 100);

        if (!value.endOn && dataRefreshed) {
            const end = DateTime.fromISO(
                DateTime.fromISO(dataRefreshed, { zone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                    .toISO()
                    .replace(/\+\d{2}:\d{2}/, 'Z'),
            );
            const totalMinutes = Math.abs(Math.floor(end.diff(start).as('minutes')));
            const daysMargin = Math.abs(Math.floor(end.diff(start).as('days'))) * 50;
            const barWidth = Math.abs((totalMinutes / durationToMinutes) * 100) + daysMargin;
            const priorityColorLinear = getPriorityColorLinear(value.priority);
            return (
                <Tooltip placement="top" PopperProps={{ style: { marginBottom: -10 } }} title={title}>
                    <div
                        style={{
                            width: `${barWidth}%`,
                            background: priorityColorLinear,
                            height: 16,
                            zIndex: 2,
                            borderRadius: 2,
                            cursor: 'pointer',
                            position: 'absolute',
                            left: `${barPosition}%`,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    ></div>
                </Tooltip>
            );
        }

        return (
            <Tooltip placement="top" PopperProps={{ style: { marginBottom: -10 } }} title={title}>
                <div
                    style={{
                        width: `${barWidth}%`,
                        background: priorityColor,
                        height: 16,
                        zIndex: 2,
                        borderRadius: 2,
                        cursor: 'pointer',
                        position: 'absolute',
                        left: `${barPosition}%`,
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}
                ></div>
            </Tooltip>
        );
    };

    return (
        <div>
            {alarmBar()}
            <div
                style={{
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    left: '50%',
                    borderLeft: '1px solid #EBEBEB',
                    transform: 'translateX(-50%)',
                }}
            />
        </div>
    );
};

export default AlarmCell;
