import { BadRequestErrorWithPaginationSortingFilteringErrorCodes } from 'api/errorCodes/badRequestErrorWithPaginationSortingFilteringErrorCodes';
import { Column, Filters, SortingRule } from 'react-table';
import { DateTime } from 'shared/luxon/luxon';
import { getLogsUser } from 'api/fetchers/logsUser';
import { LogsUserItem } from 'model/logsUser/schema';
import { LogsUserTableAccessors } from 'types/logsUser/logsUserTableAccessors';
import { QueryKeys } from 'api/queryKeys';
import { transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { useTranslation } from 'react-i18next';
import Content from 'components/content/content';
import DatepickerColumnFilter from 'components/table/components/datepickerColumnFilter/datepickerColumnFilter';
import DateTimeFormat from 'shared/luxon/DateTimeFormat';
import LoadingBar from 'components/loadingBar/loadingBar';
import LogsUserTable from 'components/table/instances/logsUserTable/logsUserTable';
import React from 'react';
import settings from 'settings';

const User: React.FC = () => {
    const { t } = useTranslation();
    const [sortingRule, setSortingRule] = React.useState<SortingRule<string> | undefined>(undefined);
    const [filters, setFilters] = React.useState<Filters<{}> | undefined>(undefined);
    const [pageIndex, setPageIndex] = React.useState<number>(0);
    const columns = React.useMemo<Column<LogsUserItem>[]>(() => {
        return [
            {
                Header: t('adminLogs.user.table.heading.date').toString(),
                accessor: LogsUserTableAccessors.occurredOn,
                Cell: ({ value }) => DateTime.fromISO(value, { zone: 'UTC+1' }).toFormat(DateTimeFormat.FULL_24_WITHOUT_SECONDS()),
                Filter: DatepickerColumnFilter,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '20%',
                },
            },
            {
                Header: t('adminLogs.user.table.heading.type').toString(),
                accessor: LogsUserTableAccessors.eventType,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '20%',
                },
                Cell: ({ value }) => <span className="test-activity">{value}</span>,
            },
            {
                Header: t('adminLogs.user.table.heading.username').toString(),
                accessor: LogsUserTableAccessors.username,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: '20%',
                },
            },
            {
                Header: t('adminLogs.user.table.heading.userEmail').toString(),
                disableFilters: false,
                disableSortBy: false,
                accessor: LogsUserTableAccessors.emailAddress,
            },
        ];
    }, [t]);

    const transformOccurredOnFilter = (filters: Filters<{}>) => {
        const occurredOnFilter = filters.find(({ id }) => id === 'occurredOn');
        const modifiedFilters = filters.filter(({ id }) => id !== 'occurredOn');
        if (occurredOnFilter && occurredOnFilter.value.isValid) {
            modifiedFilters.push({ id: 'occurredOnStart', value: occurredOnFilter.value.startOf('day').toISO() }, { id: 'occurredOnEnd', value: occurredOnFilter.value.endOf('day').toISO() });
        }
        return modifiedFilters;
    };

    const queryParams = {
        sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
        filter: filters ? transformReactTableFiltersForAPI(transformOccurredOnFilter(filters)) : [],
        dataRange: {
            from: pageIndex * settings.pageSize,
            to: pageIndex * settings.pageSize + (settings.pageSize - 1),
        },
    };
    const { data, isFetching } = useHandledQuery(
        [QueryKeys.logsUser(), queryParams],
        () => getLogsUser(queryParams.dataRange, queryParams.sort, queryParams.filter),
        {
            keepPreviousData: true,
        },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    const handleFilters = (rule: Filters<{}> | undefined) => {
        setFilters(rule);
    };

    const handlePageIndexChange = (pageIndex: number) => {
        setPageIndex(pageIndex);
    };

    return (
        <Content>
            <LoadingBar isFetching={isFetching} />
            <LogsUserTable
                columns={columns}
                data={data ? data.data : []}
                isFetching={isFetching}
                pageCount={data ? Math.ceil(data.metadata.count / settings.pageSize) : 0}
                onFilter={handleFilters}
                onPageIndexChange={handlePageIndexChange}
                onSort={handleSort}
            />
        </Content>
    );
};

export default User;
