import { DateTime } from 'shared/luxon/luxon';
import { FilterParameter, SortObject } from 'api/types';
import { Filters, SortingRule } from 'react-table';

export const transformReactTableSortingRuleForAPI = ({ id, desc }: SortingRule<unknown>): SortObject => {
    return { column: id, direction: desc ? 'desc' : 'asc' };
};

const columnsWithEqualFilterOperator = ['storeId', 'hidden', 'priority'];
const columnsWithGteFilterOperator = ['occurredOnStart', 'lastReportedDayStart'];
const columnsWithLteFilterOperator = ['occurredOnEnd', 'lastReportedDayEnd'];

const getOperatorById = (id: string) => {
    if (columnsWithEqualFilterOperator.includes(id)) {
        return 'eq';
    } else if (columnsWithGteFilterOperator.includes(id)) {
        return 'gte';
    } else if (columnsWithLteFilterOperator.includes(id)) {
        return 'lte';
    }
    return 'like';
};

const getColumnById = (id: string) => {
    if (id === 'occurredOnStart' || id === 'occurredOnEnd') {
        return 'occurredOn';
    }
    if (id === 'lastReportedDayStart' || id === 'lastReportedDayEnd') {
        return 'lastReportedDay';
    }
    return id;
};

export const transformReactTableFiltersForAPI = (filters: Filters<{}>): FilterParameter => {
    return filters.map(({ id, value }) => ({
        column: getColumnById(id),
        value,
        operator: getOperatorById(id),
    }));
};

export const formatDateForAPI = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd');
};

export const formatToDateTime = (date: DateTime) => {
    return date.toFormat('dd.MM.yyyy HH:mm:ss');
};
