import { DateTime } from 'shared/luxon/luxon';
import { EnergyMeterDateInterval } from 'types/energyMeter/energyMeterDateInterval';
import { EnergyMeterMetricType } from 'types/energyMeter/energyMeterMetricType';
import {
    FiltersActionTypes,
    FiltersState,
    SET_ALL_FILTERS_RESET,
    SET_ENERGY_CONSUMPTION_FILTER,
    SET_ENERGY_POWER_FILTER,
    SET_FOOD_SAFETY_REPORTING_DATE,
    SET_FOOD_SAFETY_STATISTICS_WEEK_AND_YEAR,
    SET_ENERGY_CONSUMPTION_AVAILABLE_DATES,
    SET_ENERGY_POWER_AVAILABLE_DATES,
    SET_FOOD_SAFETY_STATISTIC_DATE_FILTER_TYPE,
    SET_FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR,
    SET_FOOD_SAFETY_STATISTICS_QS_AND_YEAR,
    SET_ALARM_DURATION,
    SET_ALARM_PRIORITY,
    SET_ALARM_DATE_FROM,
    SET_ALARM_COUNT,
    SET_ALARM_DATE_TO,
    SET_ALARM_DATA_REFRESHED,
} from 'store/filters/types';
import { getEnergyConsumptionFilter } from 'localStorage/energyConsumptionFilter';
import { getEnergyPowerFilter } from 'localStorage/energyPowerFilter';
import { getFoodSafetyReportingFilter } from 'localStorage/foodSafetyReportingFilter';
import { getFoodSafetyStatisticsFilter } from 'localStorage/foodSafetyStatisticsFilter';
import { getEnergyConsumptionAvailableDates } from '../../localStorage/energyConsumptionAvailableDates';
import { getEnergyPowerAvailableDates } from '../../localStorage/energyPowerAvailableDates';
import { FoodSafetySegments } from 'types/foodSafety/foodSafetySegments';
import { getFoodSafetyStatisticsDateFilterType } from 'localStorage/foodSafetyStatisticDateTypeFilter';
import { getFoodSafetyStatisticsMonthAndYearFilter } from 'localStorage/foodSafetyStatisticsMonthAndYearFilter';
import { getFoodSafetyStatisticsQSAndYearFilter } from '../../localStorage/foodSafetyStatisticsQSAndYearFilter';
import { AlarmTimeDuration } from 'types/alarm/alarmTimeDuration';
import { DateTimeWithTimezone } from 'shared/utils/dateWithTimezone';

const foodSafetyReportingLS = getFoodSafetyReportingFilter();
const foodSafetyStatisticsLS = getFoodSafetyStatisticsFilter();
const foodSafetyStatisticsMonthAndYearLS = getFoodSafetyStatisticsMonthAndYearFilter();
const foodSafetyStatisticsQSAndYearLS = getFoodSafetyStatisticsQSAndYearFilter();
const foodSafetyStatisticDateFilterTypeLS = getFoodSafetyStatisticsDateFilterType();
const energyConsumptionLS = getEnergyConsumptionFilter();
const energyPowerLS = getEnergyPowerFilter();
const energyConsumptionAvailableDatesLS = getEnergyConsumptionAvailableDates();
const energyPowerAvailableDatesLS = getEnergyPowerAvailableDates();

const initialFoodSafetyReporting = { date: DateTime.now().minus({ days: 1 }) };
const initialFoodSafetyStatistics = {
    dateFilterType: FoodSafetySegments.weeks,
    weekAndYear: {
        week: DateTime.now().weekNumber,
        year: DateTime.now().year,
    },
    monthAndYear: {
        month: DateTime.now().month,
        year: DateTime.now().year,
    },
    qsAndYear: {
        quarter: DateTime.now().quarter,
        year: DateTime.now().year,
    },
};
const initialEnergyConsumption = {
    dateFrom: undefined,
    dateTo: undefined,
    availableDateFrom: undefined,
    availableDateTo: undefined,
    energyMetricType: EnergyMeterMetricType.consumptionElectricity,
    dateIntervalType: EnergyMeterDateInterval.day,
};
const initialEnergyPower = {
    dateFrom: undefined,
    dateTo: undefined,
    availableDateFrom: undefined,
    availableDateTo: undefined,
    energyMetricType: EnergyMeterMetricType.powerElectricity,
};

const initialState: FiltersState = {
    alarmReport: {
        duration: parseInt(AlarmTimeDuration.last12),
        priority: undefined,
        dateFrom: DateTimeWithTimezone.minus({ days: 1 }),
        dateTo: DateTimeWithTimezone,
        alarmCount: 0,
        dataRefreshed: null,
    },
    foodSafetyReporting: foodSafetyReportingLS ? { date: DateTime.fromMillis(foodSafetyReportingLS.date) } : initialFoodSafetyReporting,
    foodSafetyStatistics: {
        dateFilterType: foodSafetyStatisticDateFilterTypeLS ? foodSafetyStatisticDateFilterTypeLS.dateFilterType : FoodSafetySegments.weeks,
        weekAndYear: foodSafetyStatisticsLS
            ? {
                  week: foodSafetyStatisticsLS.week,
                  year: foodSafetyStatisticsLS.year,
              }
            : initialFoodSafetyStatistics.weekAndYear,
        monthAndYear: foodSafetyStatisticsMonthAndYearLS
            ? {
                  month: foodSafetyStatisticsMonthAndYearLS.month,
                  year: foodSafetyStatisticsMonthAndYearLS.year,
              }
            : initialFoodSafetyStatistics.monthAndYear,
        qsAndYear: foodSafetyStatisticsQSAndYearLS
            ? {
                  quarter: foodSafetyStatisticsQSAndYearLS.quarter,
                  year: foodSafetyStatisticsQSAndYearLS.year,
              }
            : initialFoodSafetyStatistics.qsAndYear,
    },
    energyConsumption: energyConsumptionLS
        ? {
              dateFrom: energyConsumptionLS.dateFrom ? DateTime.fromMillis(energyConsumptionLS.dateFrom) : undefined,
              dateTo: energyConsumptionLS.dateTo ? DateTime.fromMillis(energyConsumptionLS.dateTo) : undefined,
              availableDateFrom: energyConsumptionAvailableDatesLS.availableDateFrom ? DateTime.fromMillis(energyConsumptionAvailableDatesLS.availableDateFrom) : undefined,
              availableDateTo: energyConsumptionAvailableDatesLS.availableDateTo ? DateTime.fromMillis(energyConsumptionAvailableDatesLS.availableDateTo) : undefined,
              energyMetricType: energyConsumptionLS.energyMetricType,
              dateIntervalType: energyConsumptionLS.dateIntervalType,
          }
        : initialEnergyConsumption,
    energyPower: energyPowerLS
        ? {
              dateFrom: energyPowerLS.dateFrom ? DateTime.fromMillis(energyPowerLS.dateFrom) : undefined,
              dateTo: energyPowerLS.dateTo ? DateTime.fromMillis(energyPowerLS.dateTo) : undefined,
              availableDateFrom: energyPowerAvailableDatesLS.availableDateFrom ? DateTime.fromMillis(energyPowerAvailableDatesLS.availableDateFrom) : undefined,
              availableDateTo: energyPowerAvailableDatesLS.availableDateTo ? DateTime.fromMillis(energyPowerAvailableDatesLS.availableDateTo) : undefined,
              energyMetricType: energyPowerLS.energyMetricType,
          }
        : initialEnergyPower,
};

const reducer = (state = initialState, action: FiltersActionTypes): FiltersState => {
    switch (action.type) {
        case SET_ALARM_DATA_REFRESHED: {
            return {
                ...state,
                alarmReport: {
                    ...state.alarmReport,
                    dataRefreshed: action.dataRefreshed,
                },
            };
        }
        case SET_ALARM_COUNT: {
            return {
                ...state,
                alarmReport: {
                    ...state.alarmReport,
                    alarmCount: action.alarmCount,
                },
            };
        }
        case SET_ALARM_DATE_FROM: {
            return {
                ...state,
                alarmReport: {
                    ...state.alarmReport,
                    dateFrom: action.dateFrom,
                },
            };
        }
        case SET_ALARM_DATE_TO: {
            return {
                ...state,
                alarmReport: {
                    ...state.alarmReport,
                    dateTo: action.dateTo,
                },
            };
        }
        case SET_ALARM_DURATION: {
            return {
                ...state,
                alarmReport: {
                    ...state.alarmReport,
                    duration: action.duration,
                },
            };
        }
        case SET_ALARM_PRIORITY: {
            return {
                ...state,
                alarmReport: {
                    ...state.alarmReport,
                    priority: action.priority,
                },
            };
        }
        case SET_FOOD_SAFETY_REPORTING_DATE: {
            return {
                ...state,
                foodSafetyReporting: {
                    date: action.date,
                },
            };
        }
        case SET_FOOD_SAFETY_STATISTICS_WEEK_AND_YEAR: {
            return {
                ...state,
                foodSafetyStatistics: {
                    ...state.foodSafetyStatistics,
                    weekAndYear: {
                        week: action.weekAndYear.week,
                        year: action.weekAndYear.year,
                    },
                },
            };
        }
        case SET_FOOD_SAFETY_STATISTICS_MONTH_AND_YEAR: {
            return {
                ...state,
                foodSafetyStatistics: {
                    ...state.foodSafetyStatistics,
                    monthAndYear: {
                        month: action.monthAndYear.month,
                        year: action.monthAndYear.year,
                    },
                },
            };
        }
        case SET_FOOD_SAFETY_STATISTICS_QS_AND_YEAR: {
            return {
                ...state,
                foodSafetyStatistics: {
                    ...state.foodSafetyStatistics,
                    qsAndYear: {
                        quarter: action.qsAndYear.quarter,
                        year: action.qsAndYear.year,
                    },
                },
            };
        }
        case SET_FOOD_SAFETY_STATISTIC_DATE_FILTER_TYPE: {
            return {
                ...state,
                foodSafetyStatistics: {
                    ...state.foodSafetyStatistics,
                    dateFilterType: action.dateFilterType,
                },
            };
        }
        case SET_ENERGY_CONSUMPTION_FILTER: {
            return {
                ...state,
                energyConsumption: {
                    ...state.energyConsumption,
                    dateFrom: action.dateFrom,
                    dateTo: action.dateTo,
                    energyMetricType: action.energyMetricType,
                    dateIntervalType: action.dateIntervalType,
                },
            };
        }
        case SET_ENERGY_CONSUMPTION_AVAILABLE_DATES: {
            return {
                ...state,
                energyConsumption: {
                    ...state.energyConsumption,
                    availableDateFrom: action.availableDateFrom,
                    availableDateTo: action.availableDateTo,
                },
            };
        }
        case SET_ENERGY_POWER_FILTER: {
            return {
                ...state,
                energyPower: {
                    ...state.energyPower,
                    dateFrom: action.dateFrom,
                    dateTo: action.dateTo,
                    energyMetricType: action.energyMetricType,
                },
            };
        }
        case SET_ENERGY_POWER_AVAILABLE_DATES: {
            return {
                ...state,
                energyPower: {
                    ...state.energyPower,
                    availableDateFrom: action.availableDateFrom,
                    availableDateTo: action.availableDateTo,
                },
            };
        }
        case SET_ALL_FILTERS_RESET: {
            return {
                ...state,
                foodSafetyReporting: initialFoodSafetyReporting,
                foodSafetyStatistics: initialFoodSafetyStatistics,
                energyConsumption: initialEnergyConsumption,
                energyPower: initialEnergyPower,
            };
        }
        default:
            return state;
    }
};

export default reducer;
