const palette = {
    background: {
        white: 'rgba(255, 255, 255, 1)',
        light: 'rgba(248, 248, 249, 1)',
        dark: 'rgba(239, 239, 242, 1)',
        semiTransparentBlack: 'rgba(0, 0, 0, 0.8)',
    },
    text: {
        primary: 'rgba(0, 0, 0, 1)',
        secondary: 'rgba(72, 72, 72, 1)',
    },
    colors: {
        brand: {
            dark: 'rgba(21, 44, 115, 1)',
            light: 'rgba(24, 145, 246, 1)',
            transition: 'linear-gradient(225deg, #2699FF 0%, #2674FF 100%)',
        },
        success: 'rgba(97, 181, 73, 1)',
        warning: 'rgba(241, 140, 30, 1)',
        error: 'rgba(234, 0, 41, 1)',
    },
    border: 'rgba(0, 0, 0, 0.08)',
    borderDarker: 'rgba(0, 0, 0, 0.24)',
    placeholder: 'rgba(0, 0, 0, 0.32)',
    boxShadow: 'rgba(0, 0, 0, 0.48)',
};

export default palette;
