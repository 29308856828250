export const QueryKeys = {
    myProfile: () => ['myProfile'],
    userDetail: (id: number) => ['userDetail', { id }],
    stores: () => ['stores'],
    users: () => ['users'],
    storeReportFiles: () => ['storeReportFiles'],
    storeReportFileDownload: () => ['storeReportFileDownload'],
    adminUsers: () => ['adminUsers'],
    store: (id: number | null) => ['store', { id }],
    foodSafetyReport: () => ['foodSafetyReport'],
    foodSafetyReportPdf: () => ['foodSafetyReportPdf'],
    foodSafetyReportXls: () => ['foodSafetyReportXls'],
    foodSafetyReportMultiplePdfsData: () => ['foodSafetyReportMultiplePdfsData'],
    foodSafetyReportMultipleCsvData: () => ['foodSafetyReportMultipleCsvData'],
    foodSafetyStatistics: () => ['foodSafetyStatistics'],
    getFoodSafetyStatisticsMonthly: () => ['getFoodSafetyStatisticsMonthly'],
    getFoodSafetyStatisticsQuarters: () => ['getFoodSafetyStatisticsQuarters'],
    foodSafetyStatisticsMultipleCsvData: () => ['foodSafetyReportMultipleCsvData'],
    foodSafetyStatisticsPdf: () => ['foodSafetyStatisticsPdf'],
    foodSafetyStatisticsMonthlyPdf: () => ['foodSafetyStatisticsMonthlyPdf'],
    foodSafetyStatisticsQuarterlyPdf: () => ['foodSafetyStatisticsQuarterlyPdf'],
    foodSafetyStatisticsXls: () => ['foodSafetyStatisticsXls'],
    getOrganizationStructure: () => ['getOrganizationStructure'],
    energyMetricsConsumption: () => ['energyMetricsConsumption'],
    energyMetricsConsumptionXls: () => ['energyMetricsConsumptionXls'],
    energyMetricsConsumptionCsv: () => ['energyMetricsConsumptionCsv'],
    energyMetricsPower: () => ['energyMetricsPower'],
    energyMetricsPowerXls: () => ['energyMetricsPowerXls'],
    energyMetricsPowerCsv: () => ['energyMetricsPowerCsv'],
    notifications: () => ['notifications'],
    logsUser: () => ['logsUser'],
    logsReporting: () => ['logsReporting'],
    asset: (assetId: number) => ['assetId', { assetId }],
    assetCsv: () => ['assetCsv'],
    getReportingStoreIdLive: (storeId: number) => ['getReportingStoreIdLive', { storeId }],
    getAlarmReportCount: () => ['getAlarmReportCount'],
    getAlarmReportListView: () => ['getAlarmReportListView'],
    getAlarmReportTableView: () => ['getAlarmReportTableView'],
    getAlarmReportCsv: () => ['getAlarmReportCsv'],
};
