import i18n from 'localization/init';
import { DateTime } from 'luxon';
import { getDaysOfWeek } from 'shared/utils/getDaysOfWeek';
import { AlarmTimeDuration } from 'types/alarm/alarmTimeDuration';
import { AlarmPriorities } from 'types/alarm/priorities';

export const generateTimeInterval = (currentDateTime: DateTime, interval: number) => {
    let startDate = DateTime.fromISO(currentDateTime.toISO(), { zone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    if (startDate.minute !== 0) {
        startDate = startDate.set({ hour: startDate.hour + 1 });
        startDate = startDate.set({ minute: 0, second: 0, millisecond: 0 });
    }

    // Calculate the end time by subtracting [hours] hours
    const endTime = startDate.minus({ hours: interval });

    // Convert the time range to minutes
    const totalMinutes = Math.abs(Math.floor(endTime.diff(startDate).as('minutes')));
    const durationToMinutes = getDurationToMinutes(interval);

    const daysOfWeek = getDaysOfWeek();
    const results = [];

    // Perform the division in a loop
    for (let i = 0; i <= totalMinutes; i += durationToMinutes) {
        const dividedTime = startDate.minus({ minutes: i });
        const hours = dividedTime.hour;
        const minutes = dividedTime.minute;

        // Europe
        let dayOfWeek = dividedTime.weekday;
        dayOfWeek = (dayOfWeek + 6) % 7;
        const dayAcronym = daysOfWeek[dayOfWeek];

        results.push({
            id: `${dayAcronym}_${hours}_${minutes}`,
            title: `${hours}:${minutes < 10 ? '0' : ''}${minutes}`,
            subTitle: dayAcronym,
            accessor: `alarm.${dayAcronym}_${hours}_${minutes}`,
            dateTime: DateTime.fromISO(dividedTime.toISO().replace(/\+\d{2}:\d{2}/, 'Z')),
        });
    }
    results.reverse();

    return results;
};

export const getDurationToMinutes = (interval: number): number => {
    switch (interval.toString()) {
        case AlarmTimeDuration.last4:
            return 20; // 4 hours / 20 minutes = 12 columns on the table
        case AlarmTimeDuration.last12:
            return 60; // 12 columns
        case AlarmTimeDuration.last24:
            return 90; // 16 columns
        case AlarmTimeDuration.last48:
            return 180; // 16 columns
        default:
            throw new Error(`Unexpected interval ${interval}`);
    }
};

export const getPriorityText = (priority: number): string => {
    switch (priority) {
        case AlarmPriorities.Prio1:
            return i18n.t('alarmsBody.prio1');
        case AlarmPriorities.Prio2:
            return i18n.t('alarmsBody.prio2');
        default:
            return '—';
    }
};
