import { Box, makeStyles } from '@material-ui/core';
import { getAlarmReportCsv } from 'api/fetchers/alarmReportCsv';
import { QueryKeys } from 'api/queryKeys';
import AlarmFilter from 'components/alarmFilter/alarmFilter';
import Content from 'components/content/content';
import ContentHeader from 'components/contentHeader/contentHeader';
import ContentLegend from 'components/contentLegend/contentLegend';
import { ExportButtonType } from 'components/exportButtons/exportButtonProps';
import ExportButtons from 'components/exportButtons/exportButtons';
import LoadingBar from 'components/loadingBar/loadingBar';
import AlarmTableViewColumns from 'components/table/instances/alarmTableViewTable/alarmTableViewColumns';
import AlarmTableViewTable from 'components/table/instances/alarmTableViewTable/alarmTableViewTable';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { SortingRule } from 'react-table';
import { formatDateForAPI, transformReactTableFiltersForAPI, transformReactTableSortingRuleForAPI } from 'shared/network/helpers';
import { useHandledQuery } from 'shared/useHandledQuery/useHandledQuery';
import { AppState } from 'store';
import { getAlarmReportTableView } from 'api/fetchers/alarmReportTableView';
import { BadRequestErrorWithPaginationSortingFilteringErrorCodes } from 'api/errorCodes/badRequestErrorWithPaginationSortingFilteringErrorCodes';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            overflowX: 'scroll',
        },
    },
}));

const AlarmTableView: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const queryClient = useQueryClient();
    const { priority, dateFrom, dateTo } = useSelector((state: AppState) => state.filters.alarmReport);
    const { storeId } = useSelector((state: AppState) => state.store);
    const [sortingRule, setSortingRule] = useState<SortingRule<string> | undefined>(undefined);

    const {
        data: alarmReportData,
        isFetching,
        refetch: refetchAlarmReport,
    } = useHandledQuery(
        [QueryKeys.getAlarmReportTableView(), sortingRule, priority, dateFrom, dateTo],
        () =>
            storeId
                ? getAlarmReportTableView({
                      storeId,
                      dateFrom: formatDateForAPI(dateFrom),
                      dateTo: formatDateForAPI(dateTo),
                      sort: sortingRule ? [transformReactTableSortingRuleForAPI(sortingRule)] : undefined,
                      filter: transformReactTableFiltersForAPI([...(priority && priority !== 0 ? [{ id: 'priority', value: priority }] : [])]),
                  })
                : undefined,
        { keepPreviousData: true, enabled: Boolean(storeId) },
        BadRequestErrorWithPaginationSortingFilteringErrorCodes,
    );

    const {
        data: alarmReportCsvData,
        refetch: alarmReportCsvRefetch,
        isLoading: alarmReportCsvLoading,
        remove: alarmReportCsvRemove,
    } = useHandledQuery([QueryKeys.getAlarmReportCsv(), storeId], () => (storeId ? getAlarmReportCsv(storeId, formatDateForAPI(dateFrom), formatDateForAPI(dateTo)) : undefined), {
        enabled: false,
    });

    const handleSort = (rule: SortingRule<string> | undefined) => {
        setSortingRule(rule);
    };

    useEffect(() => {
        if (alarmReportCsvData) {
            const file = new Blob([alarmReportCsvData.blob]);
            FileSaver.saveAs(file, alarmReportCsvData.fileName);
            queryClient.removeQueries(QueryKeys.getAlarmReportCsv(), { exact: true });
        }
    }, [alarmReportCsvData]);

    useEffect(() => {
        return () => alarmReportCsvRemove();
    }, []);

    const columns = AlarmTableViewColumns();
    return (
        <Content>
            <div className={classes.root}>
                <LoadingBar isFetching={isFetching} />
                <ContentHeader>
                    <ContentLegend>
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                            <AlarmFilter />
                        </Box>
                    </ContentLegend>
                    <ExportButtons
                        buttons={[{ type: ExportButtonType.csv, text: t('exportButtons.timespanCsv'), action: () => alarmReportCsvRefetch(), key: 'timespanCsv', isLoading: alarmReportCsvLoading }]}
                        disabled={false}
                    />
                </ContentHeader>
                <AlarmTableViewTable columns={columns} data={alarmReportData ? alarmReportData.data.alarms : []} isFetching={isFetching} refetch={refetchAlarmReport} onSort={handleSort} />
            </div>
        </Content>
    );
};

export default AlarmTableView;
