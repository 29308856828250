import { Box } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import { makeStyles } from '@material-ui/core/styles';
import { SearchBoxProps } from 'components/organizationStructure/searchBox/searchBoxProps';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import Input from '@material-ui/core/Input';
import React from 'react';

const useStyles = makeStyles((theme) => {
    const TAB_HEIGHT = {
        small: theme.spacing(4),
        medium: theme.spacing(6),
        large: theme.spacing(7),
    };
    return {
        root: {
            position: 'relative',
            zIndex: 1,
            background: theme.customPalette.background.light,
            boxShadow: theme.customElevation.default,
            minHeight: TAB_HEIGHT.small,
            display: 'flex',
            padding: `0 ${theme.spacing(2)}`,
            [theme.breakpoints.up('sm')]: {
                minHeight: TAB_HEIGHT.medium,
                padding: `0 ${theme.spacing(3)}`,
            },
            [theme.breakpoints.up('lg')]: {
                padding: `0 ${theme.spacing(10)}`,
                minHeight: TAB_HEIGHT.large,
            },
        },
        form: {
            display: 'flex',
            width: '100%',
        },
        input: {
            fontSize: theme.typography.pxToRem(14),
            position: 'static',
            '&:before': {
                display: 'none',
            },
            '&:after': {
                borderBottomWidth: 2,
                borderBottomColor: theme.customPalette.colors.brand.light,
            },
        },
    };
});

const SearchBox: React.FC<SearchBoxProps> = ({ setFilter }) => {
    const { control, watch } = useForm();
    const watchedInput = watch('input');
    const classes = useStyles();
    const { t } = useTranslation();

    const debouncedSetFilter = React.useMemo(
        () =>
            _debounce((watchedInput) => {
                setFilter(watchedInput);
            }, 300),
        [setFilter],
    );

    React.useEffect(() => {
        debouncedSetFilter(watchedInput);
    }, [watchedInput, debouncedSetFilter]);

    return (
        <div className={classes.root}>
            <div className={classes.form}>
                <Box alignItems="center" display="flex" mr={2}>
                    <MagnifierIcon />
                </Box>
                <Controller
                    control={control}
                    defaultValue=""
                    name="input"
                    render={({ field }) => <Input autoComplete="off" autoFocus classes={{ root: classes.input }} fullWidth id="test-search" placeholder={t('searchBox.placeholder')} {...field} />}
                />
            </div>
        </div>
    );
};

export default SearchBox;
