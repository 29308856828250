import AlarmCell from 'components/table/components/alarmCell/alarmCell';
import { generateTimeInterval, getPriorityText } from 'components/table/instances/alarmListViewTable/utils';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { routes } from 'routes/routes';
import { AppState } from 'store';
import { ListViewTableAccessors } from 'types/alarm/listViewTableAccessor';
import { ListViewTableItem } from 'types/alarm/listViewTableItem';

interface Props {
    duration: number;
}

const AlarmListViewTableColumns = ({ duration }: Props) => {
    const { t } = useTranslation();
    const { dataRefreshed } = useSelector((state: AppState) => state.filters.alarmReport);
    const timeIntervalArray = generateTimeInterval(dataRefreshed ? DateTime.fromISO(dataRefreshed) : DateTime.now(), duration);

    const columns = React.useMemo<Column<ListViewTableItem>[]>(() => {
        const alarmProperties = {
            customStyles: {
                width: '150px',
                textAlign: 'center',
                position: 'relative',
            },
            Cell: AlarmCell,
        };
        return [
            {
                Header: t('alarm.listView.table.heading.coolingPosition').toString(),
                accessor: ListViewTableAccessors.position,
                disableSortBy: false,
                customStyles: {
                    width: 200,
                },
                Cell: ({ value, row }) => (
                    <Link style={{ color: 'inherit' }} to={{ state: { goBackPath: routes.alarmListView.pattern }, pathname: routes.asset.path(row.original.assetId) }}>
                        {value}
                    </Link>
                ),
            },
            {
                Header: t('alarm.listView.table.heading.case').toString(),
                accessor: ListViewTableAccessors.zone,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: 150,
                },
            },
            {
                Header: t('alarm.listView.table.heading.position').toString(),
                accessor: ListViewTableAccessors.assetName,
                disableFilters: false,
                disableSortBy: false,
                customStyles: {
                    width: 180,
                },
            },
            {
                Header: t('alarm.listView.table.heading.alarmPriority').toString(),
                accessor: ListViewTableAccessors.priority,
                disableSortBy: false,
                customStyles: {
                    width: 150,
                },
                Cell: ({ value }) => {
                    return <>{getPriorityText(value)}</>;
                },
            },
            {
                Header: '',
                id: 'timeline',
                columns: timeIntervalArray.map(({ id, title, subTitle, accessor }) => ({
                    id,
                    Header: () => {
                        return (
                            <div key={title}>
                                <div>{title}</div>
                                {subTitle && <div>{subTitle}</div>}
                            </div>
                        );
                    },
                    accessor,
                    ...alarmProperties,
                })),
            },
        ];
    }, [t, duration, dataRefreshed]);

    return columns;
};

export default AlarmListViewTableColumns;
